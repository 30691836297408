var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"编辑车间","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 14 }}},[_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'number',
            {
              rules: [
                { required: true, message: '请输入编号' },
                { max: 32, message: '超出最大长度(32)' },
              ],
              initialValue: _vm.item.number,
            },
          ]),expression:"[\n            'number',\n            {\n              rules: [\n                { required: true, message: '请输入编号' },\n                { max: 32, message: '超出最大长度(32)' },\n              ],\n              initialValue: item.number,\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入名称' },
                { max: 64, message: '超出最大长度(64)' },
              ],
              initialValue: _vm.item.name,
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入名称' },\n                { max: 64, message: '超出最大长度(64)' },\n              ],\n              initialValue: item.name,\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"激活状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'is_active',
            {
              initialValue: _vm.item.is_active,
            },
          ]),expression:"[\n            'is_active',\n            {\n              initialValue: item.is_active,\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":true}},[_vm._v("激活")]),_c('a-select-option',{attrs:{"value":false}},[_vm._v("冻结")])],1)],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              rules: [{ max: 256, message: '超出最大长度(256)' }],
              initialValue: _vm.item.remark,
            },
          ]),expression:"[\n            'remark',\n            {\n              rules: [{ max: 256, message: '超出最大长度(256)' }],\n              initialValue: item.remark,\n            },\n          ]"}],attrs:{"allowClear":true}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }